<template>
  <v-container fluid class="text-justify">
    <v-row no-gutters justify="center">
      <v-col cols="12" md="8" xl="6">
        <v-data-table
          disable-pagination
          :headers="headers"
          hide-default-footer
          :items="messages"
          no-data-text="Keine Meldungen vorhanden."
        >
          <template v-slot:item.ablaufdatum="{ item }">
            {{ formatDate(item.ablaufdatum) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              icon
              small
              @click="deleteItem(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="firstname"
            :rules="rulesText"
            label="Meldung"
            clearable
            maxlength="255"
            counter="255"
            required
          ></v-text-field>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    :rules="rulesDate"
                    label="Anzeigen bis"
                    readonly
                    prepend-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="menu2 = false" :min="today" required></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            @click="addItem"
          >
            Nachricht erstellen
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {formatDate} from "../../common/lang";
import {mapState} from 'vuex';
import {SessionRepository} from "../../repositories/session-repository";

export default {
  data() {
    return {
      firstname: "",
      headers: [
        {text: 'Nachricht', value: 'text', sortable: false},
        {text: 'Anzeigen bis', value: 'ablaufdatum'},
        {value: 'action', sortable: false, align: 'end'}
      ],
      today: new Date().toISOString().substr(0, 10),
      date: null,
      rulesDate: [
        value => !!value || "Required.",
      ],
      rulesText: [
        value => !!value || "Required.",
        value => (value && value.length >= 3) || "Min 3 characters"
      ],
      valid: false
    };
  },

  computed: {
    ...mapState('info', [
      'messages'
    ]),
  },

  methods: {
    deleteItem(id) {
      this.$store.dispatch('info/delete', id);
    },
    addItem() {
      if (this.valid) {
        this.$store.dispatch('info/save', {
          text: this.firstname,
          ablaufdatum: this.date
        });
      }
    },
    formatDate
  }
};
</script>
